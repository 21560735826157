<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import PayrollManagement from "./PayrollManagement/index.vue";
import MasterPayroll from "./MasterPayroll/index.vue";
// import EmployeeFiles from "./EmployeeFiles/index.vue";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    PayrollManagement,
    MasterPayroll,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.payroll.text')"
    />

    <b-tabs
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <!-- start Payroll Management informations -->
      <b-tab active>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("payroll.tabs.management") }}
          </span>
        </template>
        <PayrollManagement />
      </b-tab>
      <!-- end Payroll Management informations -->

      <!-- start Master Payroll informations -->
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("payroll.tabs.master") }}
          </span>
        </template>
        <MasterPayroll />
      </b-tab>
      <!-- end Master Payroll informations -->
    </b-tabs>
  </Layout>
</template>
<style scoped></style>
